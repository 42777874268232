import styled from 'styled-components'
import { Link } from 'gatsby'
import arrowRight from 'img/arrow-right.svg'


const ArrowLink = styled(Link)`
  color: ${(props) => props.theme.color.text.main};
  display:flex;
  align-items: center;
  
  &[aria-current] {
    color: ${(props) => props.theme.color.main};
  }
  
  &::before {
    content: "";
    display: block;
    background-image: url(${arrowRight});
    background-size: contain;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    margin-left: 5px;
    margin-right: 5px;
  }
`

export const ArrowLinkAnchor = styled.a`
  color: ${(props) => props.theme.color.text.main};
  display:flex;
  align-items: center;
  
  &[aria-current] {
    color: ${(props) => props.theme.color.main};
  }
  
  &::before {
    content: "";
    display: block;
    background-image: url(${arrowRight});
    background-size: contain;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    margin-left: 5px;
    margin-right: 5px;
  }
`

export default ArrowLink;
