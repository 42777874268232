/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'

import ParseContent from 'components/shared/ParseContent'
import ArrowLink from 'components/elements/ArrowLink'

// Helpers
import { getLanguage } from 'services/language'

const ServiceLink = styled(Link)`
  border: 1px solid ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.text.main};

  &[aria-current] {
    color: #fff;
    font-weight: bold;
    background: ${(props) => props.theme.color.secondary};
  }
`

const ServicesMenu = () => {
  const language = getLanguage()
  const { menuNL, menuEN } = useStaticQuery(graphql`
    query {
      menuNL: wpMenu(databaseId: { eq: 10 }) {
        menuItems {
          nodes {
            label
            url
            parentId
            childItems {
              nodes {
                title
                url
                label
              }
            }
          }
        }
      }

      menuEN: wpMenu(databaseId: { eq: 13 }) {
        menuItems {
          nodes {
            label
            url
            parentId
            childItems {
              nodes {
                title
                url
                label
              }
            }
          }
        }
      }
    }
  `)

  const items = language === 'nl_NL' ? menuNL.menuItems : menuEN.menuItems

  return (
    <div className="d-flex flex-column">
      {items.nodes.map((item, index) => (
        <ServicesMenuItem key={index} {...item} />
      ))}
    </div>
  )
}

const ServicesMenuItem = ({ url, label, childItems, parentId }) => {
  const [isActive, setActive] = useState(false)

  const isPartiallyActive = (data) => {
    let { isPartiallyCurrent } = data

    // if 'to' location is '/' and current page is home than set partially current
    if (data.location.pathname === '/' && data.href === '/home/')
      isPartiallyCurrent = true

    const hashSplit = data.href.split('#')

    // if 'to' has hash than custom check partially current
    if (hashSplit[1]) {
      hashSplit[0] = hashSplit[0].replace('/#', '')
      hashSplit[0] = hashSplit[0].replace('#', '')

      if (hashSplit[0] === data.location.pathname) {
        isPartiallyCurrent = true
      }
    }

    if (isPartiallyCurrent) {
      setActive(true)
    }

    return isPartiallyCurrent ? { 'aria-current': `page` } : null
  }

  return (
    <div>
      {parentId === null && (
        <ServiceLink
          getProps={isPartiallyActive}
          className="text-center text-uppercase font-size-m py-3 d-block mb-3"
          to={url}
        >
          {label}
        </ServiceLink>
      )}

      {parentId === null && childItems.nodes.length !== 0 && isActive && (
        <div className="d-flex flex-column py-1">
          {childItems.nodes.map((item, index) => (
            <ArrowLink
              className="mb-3 px-3 text-shadow"
              to={item.url}
              key={index}
            >
              <ParseContent content={item.label} />
            </ArrowLink>
          ))}
        </div>
      )}
    </div>
  )
}

export default ServicesMenu
