import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'
import ServicesMenu from 'components/sidebar/ServicesMenu'

// Helpers
import { getLanguage } from 'services/language'

// Third Party
import styled from 'styled-components'

const BrochureRow = styled.div`
  margin-top: -164px;

  @media screen and (max-width: 991px) {
    margin-top: 20px;
  }
`;

const Contact = ({ withServices }) => {
  const language = getLanguage()
  const { contactNL, contactEN } = useStaticQuery(graphql`
    query {
      contactNL: wpComponent(databaseId: {eq: 454}) {
        ...ContactFrag
      }

      contactEN: wpComponent(databaseId: {eq: 699}) {
        ...ContactFrag
      }
    }
  `)

  const acf = language === 'nl_NL' ? contactNL.componentSidebar : contactEN.componentSidebar

  return (
    <div className="col-lg-6 pl-3">
      <BrochureRow className="row flex-wrap justify-content-lg-end justify-content-center mb-5">
        <div className="col-lg-8">
          <div className="d-flex flex-column justify-content-lg-end justify-content-center align-items-center color-background-lightGrey px-4 p-4">
            <h3 className="color-text-contrast font-weight-light mb-3">{acf.title}</h3>

            <ButtonDefault className="" to={acf.link.url}>
              {acf.link.title}
            </ButtonDefault>
          </div>

          {withServices && (
            <div className="col-lg-12 px-5 py-5">
              <div className="d-flex flex-column justify-content-center">
                <ServicesMenu />
              </div>
            </div> 
          )}
        </div>
      </BrochureRow>
    </div>
  )
}

export default Contact
