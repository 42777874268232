import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import Hero from 'components/elements/Hero'
import Contact from 'components/sidebar/Contact'
import BeforeFooterTemplate from 'components/shared/before-footer'
import GravityForm from 'components/GravityForm'

// Third Party
import styled from 'styled-components'

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageSpare {
        sparebanner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality:100, placeholder:NONE)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
        
        sparepartsintro {
          title
          description
          formId
        }
        
        afterintro {
          title
          blocks {
            title
            description
          }
        }
        
        beforesparefooter {
          title
          link {
            title
            url
          }
          image {
            localFile {
              childImageSharp {
               gatsbyImageData(width: 1035, quality:100)
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const EndlessContainer = styled.div`
  background: ${(props) => props.theme.color.alt};
  position: relative;
  
  &::after {
    content: "";
    display:block;
    width: 150%;
    height: 100%;
    background-color: ${(props) => props.theme.color.alt};
    position: absolute;
    left: 100%;
    top: 0;
  }
  
  h2 {
    font-size: 30px;
  }
`

const PageTemplate = ({
  data: {
    page: {
     seo,
     pageSpare
    },
  },
}) => {
  return (
    <Layout>
      {seo && (
        <SEO seo={seo} socialImage={pageSpare.sparebanner.image.localFile.childImageSharp.meta.src} />
      )}

      <Hero src={pageSpare.sparebanner.image} title={pageSpare.sparebanner.title} />

      <section className="container py-5">
        <div className="row">
          <div className="col-lg-6 pr-5">
            <div className="col-lg-12 pl-0">
              <div className="ml-lg-n5 mb-5">
                <ParseContent content={pageSpare.sparepartsintro.title} />
              </div>
            </div>
            <div className="pl-lg-5">
              <ParseContent content={pageSpare.sparepartsintro.description} />
              {pageSpare.intro && pageSpare.sparepartsintro.formId && (
                <GravityForm id={pageSpare.sparepartsintro.formId} />
              )}
            </div>
          </div>

          <Contact />
        </div>
      </section>

      <section className="container mb-5">
        <EndlessContainer>
          <div className="row align-items-center p-5">
            <div className="col-lg-12">
              <h2 className="line-height-m pr-3 mb-0">{pageSpare.afterintro.title}</h2>
            </div>
          </div>
        </EndlessContainer>
      </section>

      <section className="container mb-5">
        <div className="row">
          {pageSpare.afterintro.blocks.map((block) => (
            <div key={block.title} className="col-lg-6 mb-4 pr-5">
              <span className="d-block color-text-contrast">{block.title}</span>
              <ParseContent content={block.description} />
            </div>
          ))}
        </div>
      </section>

      <BeforeFooterTemplate title={pageSpare.beforesparefooter.title} image={pageSpare.beforesparefooter.image} cta={pageSpare.beforesparefooter.link} />
    </Layout>
  )
}

export default PageTemplate
